const MenuCard = ({ name, category, price, description, isLast }) => {
  return (
    <div className="ml-[5vw]">
      <div className="flex flex-col items-start text-start gap-0 text-dark md:gap-2">
        <p className="text-2xl font-bold tracking-0.2em">
          {name}{" "}
          <span className="text-sm">{category === "Veg" ? `(V)` : ""}</span>
        </p>
        <p className="text-2xl font-bold tracking-widest">CHF {price}</p>
        <p className="text-sm">{description}</p>
      </div>

      {!isLast ? (
        <>
          {/* Render this content if not the last */}
          <span className="block my-10 w-3/5 border-b-[1px] border-dark" />
        </>
      ) : (
        <>
          {/* Render this content if it's the last */}
          <span className="block my-10" />
        </>
      )}
    </div>
  );
};

export default MenuCard;
