import { useForm } from "react-hook-form";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Edit to actually send the form data
  const onSubmit = (data) => {
    // data is an Object => { email, phone, message }
    console.log(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-7 w-full mt-10 md:grid-cols-2"
    >
      <div className="col-span-2 md:col-span-1">
        <label htmlFor="email">E-mail</label>
        <input
          type="email"
          id="email"
          {...register("email", { required: "L'e-mail est obligatoire" })}
          className="w-full p-2 border rounded-xl md:rounded"
        />
        {errors.email && <span>{errors.email.message}</span>}
      </div>

      <div className="col-span-2 md:col-span-1">
        <label htmlFor="phone">Numéro de téléphone</label>
        <input
          type="tel"
          id="phone"
          {...register("phone", { required: "Le numéro de téléphone est obligatoire" })}
          className="w-full p-2 border rounded-xl md:rounded"
        />
        {errors.phone && <span>{errors.phone.message}</span>}
      </div>

      <div className="col-span-2">
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          {...register("message", { required: "Le message est obligatoire" })}
          rows="4"
          className="w-full p-2 border rounded-xl md:rounded"
        ></textarea>
        {errors.message && <span>{errors.message.message}</span>}
      </div>

      <div className="col-span-2 flex justify-end">
        <button
          type="submit"
          className="bg-light text-dark px-6 py-2 rounded-lg font-bold hover:bg-lightDarker active:bg-lightDarker transition"
        >
          Envoyer
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
