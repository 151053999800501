import React from "react";
import "./Home.css";
import Redirect from "../components/Redirect/Redirect";
import ContactForm from "../components/ContactForm/ContactForm";
import { Images } from "../assets";

const redirectItems = [
  { src: Images.NOODLES, alt: "Nouilles", text: "a propos", id: "about" },
  { src: Images.PUDDING, alt: "Pudding", text: "menu", externalPath: "/menu" },
  { src: Images.SUSHE, alt: "Sushe", text: "contact", id: "contact" },
];

const Home = () => {
  return (
    <>
      {/* Redirects */}
      <section className="flex flex-col w-full h-auto px-3 md:px-0 md:flex-row gap-8 md:gap-0 pt-3 md:pt-0 bg-dark">
        {redirectItems.map((item, index) => (
          <Redirect key={index} {...item} />
        ))}
      </section>

      <div className="relative">
        <section className="flex flex-col">
          {/* A propos */}
          <div
            id="about"
            className="flex bg-dark text-light py-[5vh] md:py-[15vh]"
          >
            <div className="flex flex-col mx-[7vw] self-center gap-[5vh] lg:ml-[50vw] mr-[5vw]">
              <h2 className="subtitle text-start self-center md:self-start">
                A propos
              </h2>
              <div className="about text-justify">
                <p>
                  Situé au cœur de Lausanne, près de la Place Chauderon,{" "}
                  <span className="font-extraBold">Cavallo Bianco</span> vous
                  propose une cuisine italienne authentique alliant{" "}
                  <span className="font-extraBold">
                    saveurs méditerranéennes
                  </span>{" "}
                  et <span className="font-extraBold">ingrédients frais</span>.
                  Découvrez nos pizzas artisanales, pâtes faites maison et
                  desserts emblématiques comme le tiramisu, le tout dans une
                  ambiance chaleureuse et conviviale.
                </p>
                <p>
                  Accompagnez vos plats d'une sélection de vins raffinés et de
                  cocktails uniques, parfaits pour sublimer votre expérience.
                  Que ce soit pour un dîner romantique, un repas entre amis ou
                  en famille, Cavallo Bianco est l'adresse idéale à Lausanne.
                </p>
                <p>
                  <span className="font-extraBold">Réservez</span> dès
                  aujourd'hui et vivez la{" "}
                  <span className="font-extraBold">dolce vita</span> !
                </p>
              </div>
            </div>
          </div>

          {/* Pizza small screens */}
          <div className="w-full bg-dark">
            <img
              src={Images.PIZZA}
              alt="Pizza"
              className="mt-[-10%] mb-12 w-[100vw] h-auto lg:hidden inert"
            />
          </div>
        </section>

        {/* Pizza big screens */}
        <img
          src={Images.PIZZA}
          alt="Pizza"
          className="hidden absolute top-[50%] translate-x-[-30%] translate-y-[-30%] w-[100vw] max-w-[1200px] h-auto inert md:w-4/5 md:translate-y-[-70%] lg:block"
        />

        <section>
          {/* Horaires */}
          <div className="flex flex-col items-center bg-light text-dark px-5 py-[3vh] lg:flex-row lg:items-start">
            <h3 className="font-extraBold italic text-start uppercase leading-none w-[50%] items-center mt-auto mb-10 pb-5 text-[10vw] tracking-widest md:w-1/2 mt-none md:text-[6.5rem] md:pr-[8rem]">
              mamma mia !
            </h3>
            <div className="flex flex-col items-center gap-12 lg:w-1/2 lg:items-start">
              <div className="flex flex-col gap-4 w-full">
                <h2 className="subtitle self-start text-center md:text-start">
                  Horaires d'ouverture
                </h2>
                <div className="horaires w-full lg:w-1/2">
                  <h3 className="days font-bold">lundi-samedi</h3>
                  <span className="hours font-bold">10H-00H</span>
                </div>
                <div className="horaires w-full lg:w-1/2">
                  <h3 className="days font-bold">dimanche</h3>
                  <span className="hours font-bold">17H-00H</span>
                </div>
                <div className="flex items-center gap-1">
                  <img src={Images.LOCATION} alt="Icône localisation" />
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Pl.+Chauderon+24%2C+1003+Lausanne"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Pl. Chauderon 24, 1003 Lausanne
                  </a>
                </div>
              </div>

              <div className="flex flex-col gap-4 w-full">
                <h2 className="subtitle self-start text-center md:text-start">
                  Horaires de la cuisine
                </h2>
                <div className="horaires w-full lg:w-1/2">
                  <h3 className="days font-bold">lundi-samedi</h3>
                  <div className="flex flex-col">
                    <span className="hours font-bold">11H30-14H</span>
                    <span className="hours font-bold">18H30-23H</span>
                  </div>
                </div>
                <div className="horaires w-full lg:w-1/2">
                  <h3 className="days font-bold">dimanche</h3>
                  <span className="hours font-bold">18H30-23H</span>
                </div>
                <div className="flex items-center gap-1">
                  <img src={Images.LOCATION} alt="Icône localisation" />
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Pl.+Chauderon+24%2C+1003+Lausanne"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Pl. Chauderon 24, 1003 Lausanne
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Contact */}
      <section
        id="contact"
        className="flex flex-col items-center w-full py-[10vh] bg-dark text-white md:flex-row md:items-start md:pl-[7vw]"
      >
        <div className="flex flex-col items-start gap-3 w-[90%] md:w-[50%]">
          <h2 className="subtitle">Contactez-nous</h2>
          <p className="text-start text-sm md:text-base">
            Nous sommes à votre disposition pour toute demande de renseignements
            ou services.
          </p>

          <ContactForm />
        </div>
        <div className="flex flex-col items-center justify-center w-[90%] md:w-[50%] gap-12">
          <img src={Images.CONTACT} alt="" />
          <div className="flex flex-col items-center gap-8 md:items-end pr-[5vw] gap-3">
            <h3 className="subtitle">Nos coordonnées</h3>
            <a href="mailto:e-mail@info.ch" className="underline">
              e-mail@info.ch
            </a>
            <a href="tel:0216250970" className="underline">
              021 625 09 70
            </a>
            <a
              href="https://www.google.com/maps/search/?api=1&query=Pl.+Chauderon+24%2C+1003+Lausanne"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Pl. Chauderon 24, 1003 Lausanne
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
