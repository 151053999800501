import React from "react";
import { Images } from "../../assets";

const Footer = () => {
  return (
    <section className="flex flex-col items-center bg-light text-dark text-center p-4 gap-10">
      <div className="flex flex-col items-center justify-between h-[45vh] w-full sm:w-4/5 sm:h-auto sm:flex-row">
        <div>
          <img
            src={Images.BG_MAIN_LOGO}
            alt="Logo"
            className="mx-auto sm:mx-0 w-4/5 sm:w-1/2 md:w-1/3"
          />
        </div>
        <div>
          <div className="items-center w-full gap-5 sm:gap-3 flex">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Images.INSTAGRAM_ICON} alt="Instagram" />
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Images.FACEBOOK_ICON} alt="Facebook" />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Images.LINKEDIN_ICON} alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>

      <p className="text-sm tracking-wider md:text-base lg:text-xl">
        © 2020-2025 Logixcube - Cornu. Tous droits réservés.
      </p>
    </section>
  );
};

export default Footer;
